import * as React from "react";

import { PageLayout, SEO } from "@components/page-layout";
import { GrayPhrase } from "@components/typography";
import { KeyValuesSection } from "@containers/key-values";
import { CreditGradeSection } from "@containers/credit-grade";
import { StatisticsList } from "@containers/statistics";
import { Testimonials } from "@containers/testimonials";
import { CustomerService } from "@containers/customer-service";
import { PhoneScreens } from "@containers/phone-screens";
import { FaqPreviews } from "@containers/faq-previews";
import { Box } from "@components/box";
import { QuestionsTableSection } from "@containers/questions-table";
import { MainHeaderContainer } from "@containers/header";
import { FaqPreviewAnswer, QuestionsTableRow, StepsItem, Video } from "@brainfinance/icash-component-library";
import { StepsSection } from "@containers/steps";

// Images
import trsipilotWidget from "@images/trustpilot-widget-img.png";
import imgHeader from "@images/personal-loans-bg.jpg";
import postImg from "@images/fullscreen-background.jpg";

// Static data
import { FAQPERSONAL } from "@containers/faq-previews/faq-previews-data";
import { STEPSPERSONAL } from "@containers/steps/steps-data";
import { QUESTIONSPERSONAL } from "@containers/questions-table/questions-data";

const PersonalLoans = () => (
  <PageLayout>
    <SEO title="Personal loans" />
    <MainHeaderContainer
      description="A Personal loan is a great way to cover expenses like preparing for the arrival of a new baby, a home improvement project, paying for your wedding or travelling abroad."
      src={imgHeader}
      srcSet=""
    >
      <GrayPhrase>Personal loans</GrayPhrase> to
      <br />
      cover your <br />
      expenses
    </MainHeaderContainer>
    <Box className="h-[4rem]" />
    <StepsSection
      title="How personal loans works"
      gray="personal loans"
    >
      {STEPSPERSONAL.map((item, ndx) => (
        <StepsItem key={ndx} {...item} />
      ))}
    </StepsSection>
    <PhoneScreens />
    <StatisticsList />
    <QuestionsTableSection
      title="Most common questions"
      gray="questions"
    >
      {QUESTIONSPERSONAL.map((question, ndx) => (
        <QuestionsTableRow key={ndx} {...question} />
      ))}
    </QuestionsTableSection>
    <Video src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" poster={postImg} />
    <CreditGradeSection />
    <KeyValuesSection />
    <Box display="flex" className="justify-center py-[8rem] md:py-[5rem]">
      <img
        src={trsipilotWidget}
        alt="Trustpilot Widget"
        className="w-full max-w-[1408px]"
      />
    </Box>
    <FaqPreviews
      title="Frequently asked questions"
      gray="questions"
    >
      {FAQPERSONAL.map((item, ndx) => (
        <FaqPreviewAnswer key={ndx} question={item.question}>
          {item.answer}
        </FaqPreviewAnswer>
      ))}
    </FaqPreviews>
    <CustomerService />
    <Testimonials title="Find out what our customers think about our credit builder loans!" />
  </PageLayout>
);

export default PersonalLoans;
